@media print {
  /* line 13, node_modules/bootstrap/scss/_print.scss */
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  /* line 24, node_modules/bootstrap/scss/_print.scss */
  a:not(.btn) {
    text-decoration: underline; }
  /* line 34, node_modules/bootstrap/scss/_print.scss */
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  /* line 49, node_modules/bootstrap/scss/_print.scss */
  pre {
    white-space: pre-wrap !important; }
  /* line 52, node_modules/bootstrap/scss/_print.scss */
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  /* line 63, node_modules/bootstrap/scss/_print.scss */
  thead {
    display: table-header-group; }
  /* line 67, node_modules/bootstrap/scss/_print.scss */
  tr,
  img {
    page-break-inside: avoid; }
  /* line 72, node_modules/bootstrap/scss/_print.scss */
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  /* line 79, node_modules/bootstrap/scss/_print.scss */
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  /* line 92, node_modules/bootstrap/scss/_print.scss */
  body {
    min-width: 992px !important; }
  /* line 95, node_modules/bootstrap/scss/_print.scss */
  .container {
    min-width: 992px !important; }
  /* line 100, node_modules/bootstrap/scss/_print.scss */
  .navbar {
    display: none; }
  /* line 103, node_modules/bootstrap/scss/_print.scss */
  .badge {
    border: 1px solid #000; }
  /* line 107, node_modules/bootstrap/scss/_print.scss */
  .table {
    border-collapse: collapse !important; }
    /* line 110, node_modules/bootstrap/scss/_print.scss */
    .table td,
    .table th {
      background-color: #fff !important; }
  /* line 116, node_modules/bootstrap/scss/_print.scss */
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important; } }
